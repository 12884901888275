import React, { useLayoutEffect, useContext } from 'react';
import Layout from '../components/layout';
import { ThemeContext } from '../providers/theme';
import Products from '../components/Products';
import InfoCards from '../components/InfoCard';
import InfoContainer from '../components/InfoContainer';
import SEO from '../components/seo';
import { handleProducts } from '../utils/handleProducts';
import { graphql, useStaticQuery } from 'gatsby';
import Breadcrumbs from '../components/Breadcrumbs';

function Mousserende() {
  const { setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    setTheme('bubbles');
  }, []);

  const data = handleProducts(
    useStaticQuery(graphql`
      {
        allSitePage(
          filter: {
            context: {
              productType: { eq: "mousserende" }
              isProduct: { eq: "true" }
            }
          }
        ) {
          edges {
            node {
              path
              context {
                productTitle
                productPrice
                productOldprice
                productLink
                productImage
                productDescription
                productCategory
                productAvailable
                productType
              }
            }
          }
        }
      }
    `)
  );

  return (
    <React.Fragment>
      <SEO
        title={'Mousserende vin'}
        description={
          'Find den helt rigtige mousserende vin. Vi samler udvalg i samarbejde med en håndfuld af landets vinforhandlere, for at gøre det nemt for dig at finde den helt rette flaske'
        }
      />
      <Layout themeColor="#d4c52d" title="Mousserende">
        <Products type="mousserende" data={data} />
        <InfoCards
          firstColumn={{
            title: 'Mousserende vin til enhver smag',
            text:
              'Vi bestræber os på at kunne præsentere så mange forskellige slags mousserende vin for dig, da du så kan finde den der passer helt perfekt til dig og dine smagsløg. Når du har fundet den mousserende vin som du ønsker at købe, bliver du sendt videre til forhandleren. '
          }}
          secondColumn={{
            title: 'Forskellige slags mousserende vine',
            text:
              'Der findes et hav af forskellige slags mousserende vine, som kan bruges til for eksempel velkomstdrinks eller drikkes til søndagsbrunchen. '
          }}
          thirdColumn={{
            title: 'Mousserende vine fra forskellige lande',
            text:
              'Den mousserende vin bliver produceret i forskellige udgaver og kommer fra forskellige lande. Nogle af de mest kendte mousserende vin er Cava fra Spanien, Asti fra Italien og Prosecco fra Italien. '
          }}
        />
        <InfoContainer
          title={'Vidste du...'}
          text={
            'At mousserende vin er egentlig vin tilsat kulsyre, hvor man typisk følger den traditionelle champagne metode, som er at man foretager vinens gæringsproces over to fermenteringer. \n\nMan tilsætter en gær og sukker blanding til basisvinen., hvorefter man lukker flasken og holdes lukket i minimum et år. Dette giver vinen tid til at modne på bundfaldet. \n\nNår bundfaldet er samlet i flaskens hals, skal man degorgere. Dette betyder at flaskehalsen nedkøles, hvorefter man ved at poppe flasken ikke mister kulsyren i vinen. Afslutningsvist tilsætter man en blanding af vin og sukker, hvor mængden af sukker afgør hvor sød eller tør vinen skal være. \n\nMan kan dog også producere vinen på en anden og billigere måde, hvor den sekundær fermentering foregår i store gæringstanke. \n\nMousserende vin kan også sagtens drikkes til et måltid mad, dog afhænger det meget af vinens tørhedsgrad og smag. En tør mousserende vin passer godt til for eksempel østers og kaviar, men også til skaldyr og fisk, eller ris og tapas. '
          }
        />
        <Breadcrumbs type={'mousserende'} />
      </Layout>
    </React.Fragment>
  );
}

export default Mousserende;
